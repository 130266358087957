import { db } from '../main'

export default {
  getEvents() {
    let events = [];
    db.collection("events")
      .where("active", "==", true)
      .get()
      .then((res) => {
        res.forEach((snap) => {
          events.push(snap.data());
        });
      });
    return events;
  },
  getEvent(id) {
    let event = {};
    try {
      db.collection("events")
        .doc(id)
        .get()
        .then((res) => {
          if (res.exists) {
            event = res.data();
          } else {
            console.log("No such document!");
          }
        });
    } catch (error) {}
    return event;
  },
  getAllEvents() {
    let events = [];
    db.collection("events")
      .get()
      .then((res) => {
        res.forEach((snap) => {
          events.push(snap.data());
        });
      });
    return events;
  },
  getEspecialidades() {
    return [
      "Administración",
      "Anatomiía Patologíca",
      "Anestesiología",
      "Angilogía y Cirugía Vascular",
      "Cardiología",
      "Cardiología Pedrátrica",
      "Cirugía General",
      "Cirugía Laparoscópica",
      "Cirugía Plastíca",
      "Cirugía Oncológica",
      "Cirugía Pediátrica",
      "Computación",
      "Dermatología",
      "Derecho",
      "Ecografía",
      "Economía",
      "Endocrinología",
      "Enfermería",
      "Endoscopia Digestiva",
      "Estudios Internacionales",
      "Fisiatría",
      "Fisioterapia y rehabilitación",
      "Gastroenterlogía",
      "Gastroenterología Pediátrica",
      "Ginecología y Obstetricia",
      "Hematología",
      "Infectología",
      "Ingeniería",
      "Inspector en Salud",
      "Informática",
      "Mastología",
      "Medicina Crítica",
      "Medcina Familiar",
      "Medicina General",
      "Micología",
      "Microiología",
      "Nefrología",
      "Neurología",
      "Nefrología",
      "Odontología",
      "Oncología Médica",
      "Oncología Pediátrica",
      "Ortpedia y Traumatología",
      "Otorrinología",
      "Patología",
      "Psicología",
      "Psiquiatría",
      "Peditaría",
      "Publicidad y Mercadeo",
      "Radiología e Imagen",
      "Técnico Radiólogo",
      "Terapista Cardiorespiratorio",
      "Terapista Ocupacional",
      "Urología",
      "Otro (Señale su especialidad en notas)",
    ]
  }
};


