<template>
  <v-main class="pt-4">
    <section id="slide" class="white px-3">
      <section-header
        header="Agende su Evento"
        sub-header="PRÓXIMOS EVENTOS"
      />
      <v-container fluid info>
        <v-row justify="center">
          <v-col cols='12' sm="4" v-for="(item,index) in eventsOrderBystartingDate" :key="index">
            <v-card :loading="loading" class="mx-auto my-2" max-width="350" min-height="590">
              <img width="100%" height="420" :src="item.photo_url"/>
              <v-card-text>
               <div class="my-2 font-weight-bold primary--text" v-html="item.title"></div>
               <div class="accent-text" v-html="item.date" ></div>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-actions>
                <v-spacer />
                <v-btn depressed small color="primary" :to='`/event/${item.id}`'>
                  <v-icon class="mr-2">mdi-format-list-bulleted-square</v-icon> + INFO
                </v-btn>
                <v-btn depressed small color="secondary" :to='`/event/register/${item.id}`'>
                  <v-icon class="mr-2">mdi-pencil</v-icon> Inscripción
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </v-main>
</template>

<script>
  import EventService from '@/services/EventService'
  export default {
    name: 'SlideEvents',

     mounted() {
      this.getData()
    },
    data: () => ({
      events: [],
      loading: true,
      selection: 1,
    }),
    methods: {
      async getData(){
        let me = this
        this.loading = true;
        try {
          me.events = await EventService.getEvents();
          // me.events.sort((a, b) => new Date(b.starting_date) - new Date(a.starting_date) )
          // console.log(me.events)
        }
        catch (e) {
          console.log(e)
        }
        finally {
          this.loading = false;
        }
      }
    },
    computed: {
      eventsOrderBystartingDate(){
        return this.events.sort((b, a) => new Date(b.starting_date) - new Date(a.starting_date) )
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
